import Link from "next/link";
import { IoPersonOutline } from "react-icons/io5";
import Image from "next/image";
import AuthLogo from "@/assets/images/impact_dw_logo.svg";
import { useModal } from "@/components/layouts/modals/ModalContext";

export default function NoAuthNavBar() {
  const { openLoginModal } = useModal();

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="container mx-auto px-4 flex justify-between items-center py-4">
      <div className="flex items-center">
      <a href="/">
        <Image
          src={AuthLogo}
          alt="Logo"
          width={120}
          height={40}
        />
      </a>

      </div>
      
      <div className="flex space-x-4 items-center">
        <span
          className="cursor-pointer text-black hover:text-gray-400"
          onClick={scrollToTop}
        >
          Home
        </span>
        <span
          className="cursor-pointer text-black hover:text-gray-400"
          onClick={() => scrollToSection("about-us-section")}
        >
          About Us
        </span>
        <span
          className="cursor-pointer text-black hover:text-gray-400"
          onClick={() => scrollToSection("infogram-section")}
        >
          Start Order
        </span>
        <div className="flex items-center cursor-pointer" onClick={openLoginModal}>
          <span className="text-black hover:text-gray-400">Log In</span>
          <IoPersonOutline className="text-xl text-black" />
        </div>
      </div>
    </div>
  );
}




