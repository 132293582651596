import React from 'react';
import NormalText from '../atoms/footer/NormalText';
import { IoMailOutline, IoCallOutline } from "react-icons/io5";

export default function FooterLayout() {
  return (
    <div className=''>
      <div className="bg-white pb-8">
        <hr className="absolute left-4 right-4 border-t-2 border-customRed" />
        <div className="container mx-auto px-4 flex justify-between items-start text-black pt-4 relative z-10">
          <div className="flex flex-col">
            <NormalText text="Contact:" className="font-bold" />
            <NormalText text="(786) 333 9773" icon={<IoCallOutline />} />
            <NormalText text="impactdw@gmail.com" icon={<IoMailOutline />} />
          </div>
          <div className="flex flex-col items-center">
            <NormalText text="Legal:" className="font-bold" />
            <NormalText text="Terms and Conditions" className="underline" />
            <NormalText text="Privacy policy" className="underline" />
          </div>
          <div className="flex flex-col items-end">
            <NormalText text="Hours of Operation:" className="font-bold" />
            <NormalText text="24 Hours" />
          </div>
        </div>
      </div>

      <div className="bg-customRed text-white py-4">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-center">
            <div className="text-lg">
              <NormalText text="© Copyright 2024 - Impact DW Doors and Windows SAS" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

