import { useState } from "react";
import { useUserAccountQuery, useLogoutMutation } from "@/redux/features/authApiSlice";
import { IoPersonOutline } from "react-icons/io5";
import Image from "next/image";
import AuthLogo from "@/assets/images/impact_dw_logo.svg";
import { useModal } from "@/components/layouts/modals/ModalContext";
import Link from "next/link";

export default function AuthNavBar() {
  const { data: user } = useUserAccountQuery();
  const [logout, { isLoading, isError }] = useLogoutMutation();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { openLoginModal } = useModal();

  const toggleLogoutPopup = () => {
    setShowLogoutPopup(!showLogoutPopup);
  };

  const handleLogout = async () => {
    try {
      await logout();
      setShowLogoutPopup(false);
      window.location.href = '/';
    } catch (error) {
      setErrorMessage("Error during logout");
      console.error('Error during logout: ', error);
    }
  };

  return (
    <div className="container mx-auto px-4 flex justify-between items-center py-4">
      <div className="flex items-center">
        <Link href="/">
          {/* No need for <a> tag; Link wraps the content automatically */}
          <Image src={AuthLogo} alt="Logo" width={120} height={40} />
        </Link>
      </div>

      <div className="flex space-x-4 items-center relative">
        <Link href="/" className="cursor-pointer text-black hover:text-gray-400">
          Home
        </Link>
        <Link href="/#about-us-section" className="cursor-pointer text-black hover:text-gray-400">
          About Us
        </Link>
        <Link href="/#infogram-section" className="cursor-pointer text-black hover:text-gray-400">
          Start Order
        </Link>

        {user ? (
          <div className="flex items-center cursor-pointer relative" onClick={toggleLogoutPopup}>
            <span className="font-bold text-black mr-2">{user.name}</span>
            <IoPersonOutline className="text-xl text-black" />
            {showLogoutPopup && (
              <div className="absolute top-12 right-0 bg-white border border-gray-200 shadow-lg rounded-md p-4 z-50 w-64">
                <>
                  <p className="text-sm text-gray-600">{user.email}</p>
                  <hr className="my-2" />
                  <button
                    className="bg-customRed text-white py-2 px-4 rounded-md w-full hover:bg-customRed"
                    onClick={handleLogout}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Logging out...' : 'Logout'}
                  </button>
                  {isError && (
                    <p className="text-red-500 mt-2">{errorMessage}</p>
                  )}
                </>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center cursor-pointer" onClick={openLoginModal}>
            <span className="text-black hover:text-gray-400">Log In</span>
            <IoPersonOutline className="text-xl text-black" />
          </div>
        )}
      </div>
    </div>
  );
}
