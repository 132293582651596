"use client";

import { useAppSelector } from "@/redux/hooks";
import AuthNavBar from "@/components/molecules/header/AuthNavBar";
import NoAuthNavBar from "@/components/molecules/header/NoAuthNavBar";

export default function Header() {
  const { isAuthenticated } = useAppSelector((state) => state.authState);
  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-md z-50 border-b-4 border-red-500">
      {isAuthenticated ? <AuthNavBar /> : <NoAuthNavBar />}
    </header>
  );
}

