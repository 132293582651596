"use client"
import { ModalProvider } from "@/components/layouts/modals/ModalContext";
import Header from "@/components/organisms/Header";
import Footer from "@/components/organisms/Footer";
import CustomProvider from "@/redux/provider";
import { Inter } from "next/font/google";
import "@/styles/globals.css";

const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en">
      <body className={`${inter.className} flex flex-col min-h-screen`}>
        <CustomProvider>
          <ModalProvider>
            <div className="flex flex-col flex-1">
              <Header />
              <main className="flex-1 flex items-center justify-center">
                {children}
              </main>
              <Footer />
            </div>
          </ModalProvider>
        </CustomProvider>
      </body>
    </html>
  );
}

