import { IModel } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  isAccountAccepted: false,
  requestStatus: null,
  isAdmin: false,
  user: null,
  isProfileUpdated: false,
} as IModel.IAuthState;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state) => {
      state.isAuthenticated = true;
    },
    setAdmin: (state) => {
      state.isAdmin = true;
    },
    unSetAdmin: (state) => {
      state.isAdmin = false;
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    finishInitialLoad: (state) => {
      state.isLoading = false;
    },
    setAccountAccepted: (state) => {
      state.isAccountAccepted = true;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    resetRequestStatus: (state) => {
      state.requestStatus = null;
    },
    setProfileUpdated: (state) => { 
      state.isProfileUpdated = true;
    },
    resetProfileUpdated: (state) => {  
      state.isProfileUpdated = false;
    },
  },
});

export const { 
  setAuth, 
  setLogout,
  setAdmin,
  unSetAdmin, 
  finishInitialLoad, 
  setAccountAccepted, 
  setRequestStatus, 
  resetRequestStatus,
  setProfileUpdated,  
  resetProfileUpdated,
} = authSlice.actions;

export default authSlice.reducer;
