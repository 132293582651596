import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface ModalContextProps {
  isLoginModalOpen: boolean;
  isRegisterModalOpen: boolean;
  isAccountAcceptedModalOpen: boolean;
  openLoginModal: () => void;
  closeLoginModal: () => void;
  openRegisterModal: () => void;
  closeRegisterModal: () => void;
  openAccountAcceptedModal: (content: ReactNode) => void;
  closeAccountAcceptedModal: () => void;
  modalContent: ReactNode;
  hasShownAccountAcceptedModal: boolean;
  resetAccountAcceptedModalFlag: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isAccountAcceptedModalOpen, setAccountAcceptedModalOpen] = useState(false); 
  const [modalContent, setModalContent] = useState<ReactNode>(null); 
  const [hasShownAccountAcceptedModal, setHasShownAccountAcceptedModal] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('hasShownAccountAcceptedModal') === 'true';
    }
    return false;
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('hasShownAccountAcceptedModal', hasShownAccountAcceptedModal.toString());
    }
  }, [hasShownAccountAcceptedModal]);

  const openLoginModal = () => setLoginModalOpen(true);
  const closeLoginModal = () => setLoginModalOpen(false);

  const openRegisterModal = () => setRegisterModalOpen(true);
  const closeRegisterModal = () => setRegisterModalOpen(false);

  const openAccountAcceptedModal = (content: ReactNode) => {
    setModalContent(content);
    setAccountAcceptedModalOpen(true);
    setHasShownAccountAcceptedModal(true);
  };
  
  const closeAccountAcceptedModal = () => {
    setModalContent(null);
    setAccountAcceptedModalOpen(false);
  };

  const resetAccountAcceptedModalFlag = () => {
    setHasShownAccountAcceptedModal(false);
    localStorage.removeItem('hasShownAccountAcceptedModal');
  };

  return (
    <ModalContext.Provider value={{
      isLoginModalOpen,
      isRegisterModalOpen,
      isAccountAcceptedModalOpen,
      openLoginModal,
      closeLoginModal,
      openRegisterModal,
      closeRegisterModal,
      openAccountAcceptedModal,
      closeAccountAcceptedModal,
      modalContent,
      hasShownAccountAcceptedModal,
      resetAccountAcceptedModalFlag,
    }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

