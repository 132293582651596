import { ReactNode } from "react";

export default function NormalText({
  text,
  icon,
  className = "",
}: {
  text: string;
  icon?: ReactNode; 
  className?: string;
}) {
  return (
    <div className={`relative ${className}`}>
      {icon && (
        <span className="inline-block relative mr-2">
          {icon}
        </span>
      )}
      <a href="#" className="inline-block relative">
        {text}
      </a>
    </div>
  );
}

  